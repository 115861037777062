<template>
    <!-- 设置微信推送弹窗 -->
    <el-dialog
        title="登录过期，请重新登录！"
        :visible.sync="dialogTableVisible"
        center
        :append-to-body="true"
        :show-close="false"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        class="login_timeout_dialog"
    >
        <!-- <div class="ticket_div">
      <p>点击按钮退出登录</p>
    </div> -->
        <span slot="footer" class="dialog-footer">
            <el-button type="danger" @click="logout()" size="small">
                &emsp;确&emsp;定&emsp;
            </el-button>
            <el-button type="primary" @click="closeDialog()" size="small">
                &emsp;取&emsp;消&emsp;
            </el-button>
        </span>
        <login-dialog
            v-if="loginDialogVisible"
            ref="LoginDialog"
        ></login-dialog>
    </el-dialog>
</template>
<script>
import LoginDialog from "@/components/register/WechatLoginDialog";
export default {
    data() {
        return {
            dialogTableVisible: false,
            loginDialogVisible: false,
        };
    },
    components: { LoginDialog },
    mounted() {},
    methods: {
        init() {
            this.dialogTableVisible = true;
        },
        //获取微信二维码
        logout() {
            this.removeUserId();
            this.removeToken();
            this.recoverLoginId();

            // location.reload()
            this.login();
            this.dialogTableVisible = false;
        },
        login() {
            this.loginDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.LoginDialog.init();
            });
        },
        closeDialog() {
            this.dialogTableVisible = false;
        },

        removeToken() {
            this.$store.commit("user/editToken", "");
        },
        removeUserId() {
            this.$store.commit("user/editUserId", "");
        },
        recoverLoginId() {
            this.$store.commit("user/editLoginId", "personal");
        },
    },
};
</script>
<style lang="stylus" scoped>
.login_timeout_dialog{

  .el-dialog__wrapper {
    width: 50%;
    min-width: 40rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
.ticket_div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.el-image {
  width: 30rem;
  height: 30rem;
}
</style>
