<template>
    <NormalDialog
        width="500px"
        top="10vh"
        :height="40"
        :visible.sync="dialogTableVisible"
        class="three-step"
    >
        <template #title>
            <img
                :src="imageCompanyId"
                alt=""
                width="120"
                class="company-id-img"
            />
        </template>
        <template #content>
            <div class="body">
                <div>
                    <span class="font_step">简单三步，立即完成</span>
                    <el-steps
                        simple
                        :active="stepIndex"
                        class="step"
                        align-center
                        finish-status="success"
                    >
                        <el-step
                            title="填写企业名称"
                            icon="el-icon-office-building"
                            class="first-step"
                        ></el-step>
                        <el-step
                            icon="el-icon-s-order"
                            title="登记资料"
                            v-if="stepTwoFlag == 0"
                        ></el-step>
                        <el-step
                            icon="el-icon-upload"
                            title="发起请求"
                            v-if="stepTwoFlag == 1"
                        ></el-step>
                        <el-step
                            title="等待审核"
                            icon="el-icon-s-check"
                            class="last-step"
                        ></el-step>
                    </el-steps>
                </div>
                <div class="form-wrap" v-if="stepIndex !== 2">
                    <el-form
                        ref="companyFormRef"
                        :inline="true"
                        :model="companyForm"
                        label-position="top"
                        label-width="90px"
                        :rules="companyFormRules"
                    >
                        <div class="step_class">
                            <div class="font_size" v-if="stepIndex != 2">
                                {{ stepTwoFlag == 0 ? "企业名称" : "同名企业" }}
                            </div>
                            <el-form-item
                                prop="companyName"
                                v-if="stepTwoFlag == 0"
                            >
                                <div class="inputSearch" v-if="stepIndex != 2">
                                    <el-input
                                        clearable
                                        v-model="companyForm.companyName"
                                        placeholder="请输入企业名称"
                                        @clear="blurForBug()"
                                        class="inline-input"
                                        :disabled="disabled"
                                        @input="isCompanyName()"
                                    ></el-input>
                                </div>
                            </el-form-item>
                        </div>
                        <div v-if="stepIndex == 1">
                            <div v-if="stepTwoFlag == 0">
                                <div class="font_size">
                                    企业电话 （请如实填写）
                                </div>
                                <el-form-item prop="companyTel">
                                    <el-input
                                        v-model="companyForm.companyTel"
                                        placeholder="请输入企业电话"
                                        maxlength="14"
                                        show-word-limit
                                        class="inline-input"
                                        @input="isCompanyAll()"
                                    ></el-input>
                                </el-form-item>
                                <div class="font_size">企业地址</div>
                                <el-form-item prop="companyAddress">
                                    <el-input
                                        type="textarea"
                                        v-model="companyForm.companyAddress"
                                        class="inline-input"
                                        size="medium"
                                        placeholder="请输入企业地址"
                                        @input="isCompanyAll()"
                                    ></el-input>
                                </el-form-item>
                                <div class="font_size">
                                    邀请码（没有可不填）
                                </div>
                                <el-form-item prop="invitationCode">
                                    <el-input
                                        type="input"
                                        v-model="companyForm.invitationCode"
                                        size="medium"
                                        placeholder="请输入邀请码"
                                        class="inline-input"
                                    ></el-input>
                                </el-form-item>
                                <div class="font_size">营业执照</div>
                                <el-form-item>
                                    <el-upload
                                        class="license-uploader"
                                        action=""
                                        :multiple="false"
                                        name="file"
                                        accept="image/*"
                                        :http-request="uploadLicense"
                                        :on-remove="avataListRemove"
                                        :before-upload="beforeUpload"
                                    >
                                        <img
                                            v-if="localImageUrl"
                                            :src="localImageUrl"
                                            class="license"
                                        />
                                        <i
                                            v-else
                                            class="el-icon-plus license-uploader-icon"
                                        ></i>
                                    </el-upload>
                                </el-form-item>
                            </div>
                            <div
                                v-if="stepTwoFlag == 1"
                                style="margin-top: 10px"
                            >
                                <el-card shadow="hover">
                                    <div class="jionCompany">
                                        <div class="message">
                                            <el-checkbox
                                                v-model="checked"
                                                style="padding: 0 10px"
                                                :disabled="!canJoin"
                                            ></el-checkbox>
                                            <div class="Cmessage">
                                                <div
                                                    :style="[
                                                        checked
                                                            ? {
                                                                  color: '#b87100',
                                                              }
                                                            : {},
                                                    ]"
                                                >
                                                    {{
                                                        getCampany.company.cname
                                                    }}
                                                </div>
                                                <div
                                                    :style="[
                                                        checked
                                                            ? {
                                                                  color: '#b87100',
                                                                  'margin-top':
                                                                      '4px',
                                                              }
                                                            : {
                                                                  color: '#999',
                                                                  'margin-top':
                                                                      '4px',
                                                              },
                                                    ]"
                                                >
                                                    <div class="admin">
                                                        <p>管理员：</p>
                                                        <p
                                                            v-if="
                                                                Cadmin.adminName
                                                            "
                                                        >
                                                            {{ Cadmin.adminName
                                                            }}{{
                                                                telDeal(
                                                                    Cadmin.adminTel,
                                                                )
                                                            }}
                                                        </p>
                                                        <p v-else>暂无</p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="join-status"
                                                    :style="[
                                                        checked
                                                            ? {
                                                                  color: '#b87100',
                                                                  'margin-top':
                                                                      '4px',
                                                              }
                                                            : {
                                                                  color: '#999',
                                                                  'margin-top':
                                                                      '4px',
                                                              },
                                                    ]"
                                                >
                                                    {{
                                                        isJion(
                                                            $store.state.user
                                                                .userId,
                                                            getCampany.company
                                                                .isaccess,
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style="
                                                display: flex;
                                                align-items: center;
                                            "
                                        >
                                            <el-image
                                                v-if="
                                                    getCampany.company
                                                        .isaccess == 0
                                                "
                                                style="
                                                    width: 16px;
                                                    height: 16px;
                                                "
                                                :src="imageDSH"
                                            ></el-image>
                                            <el-image
                                                v-if="
                                                    getCampany.company
                                                        .isaccess == 1
                                                "
                                                style="
                                                    width: 20px;
                                                    height: 20px;
                                                "
                                                :src="imageYRZ"
                                            ></el-image>
                                            <span
                                                style="
                                                    color: #999;
                                                    padding: 0px 6px;
                                                    font-weight: normal;
                                                "
                                            >
                                                {{
                                                    getCampany.company
                                                        .isaccess == 0
                                                        ? "待审核"
                                                        : "认证成功"
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </el-card>
                                <div>
                                    <p
                                        style="
                                            color: #999;
                                            margin-top: 20px;
                                            font-size: 14px;
                                        "
                                    >
                                        注意
                                        <br />
                                        1.加入企业后您的资产不会自动并入企业
                                        <br />
                                        2.退出企业后资产不可回退，请谨慎加入
                                        <br />
                                        3.加入企业后您可以使用企业账户的资产
                                    </p>
                                </div>
                            </div>
                        </div>
                        <el-button
                            v-if="checked && stepTwoFlag == 1"
                            type="danger"
                            class="next-btn"
                            plain
                            style="width: 100px"
                            @click="submitApply"
                        >
                            申请加入
                        </el-button>
                        <el-button
                            v-if="stepTwoFlag == 1"
                            type="danger"
                            class="pre-btn"
                            plain
                            @click="closeDialog"
                        >
                            关闭
                        </el-button>
                    </el-form>
                </div>
                <div style="margin-top: 20px" v-if="stepIndex == 2">
                    <div class="content-mess">
                        <p style="font-size: 24px">提交成功！</p>
                        <img :src="imageSuccess" alt="" width="200" />

                        <p style="font-size: 20px" v-if="stepTwoFlag == 0">
                            请等待系统审核
                        </p>
                        <p style="font-size: 20px" v-if="stepTwoFlag == 1">
                            请等待企业管理员审核
                        </p>
                    </div>
                </div>
            </div>
            <el-row
                v-if="stepIndex == 0"
                slot="footer"
                type="flex"
                justify="end"
                style="width: 100%"
            >
                <el-button
                    type="danger"
                    plain
                    :class="buttonDisabled ? 'btn' : `next-btn`"
                    style="margin-top: 0px"
                    :disabled="buttonDisabled"
                    @click="nextStep"
                >
                    下一步
                </el-button>
            </el-row>
            <el-row
                v-if="stepIndex == 1"
                slot="footer"
                type="flex"
                justify="end"
                style="width: 100%"
            >
                <el-button plain @click="preStep" class="pre-btn" type="danger">
                    上一步
                </el-button>
                <el-button
                    v-if="stepTwoFlag == 0"
                    type="danger"
                    :class="!submitDisabled ? `next-btn` : 'btn'"
                    plain
                    @click="submitMess"
                    :disabled="submitDisabled"
                >
                    提交
                </el-button>
            </el-row>
            <el-row
                v-if="stepIndex == 2"
                slot="footer"
                type="flex"
                justify="end"
                style="width: 100%"
            >
                <el-button
                    type="danger"
                    plain
                    class="next-btn"
                    @click="closeDialog"
                >
                    完成
                </el-button>
            </el-row>
        </template>
    </NormalDialog>
</template>
<script>
import {
    companyApplyCertification,
    getAllCompanyStaff,
    staffApplyJoin,
    uploadFile,
} from "@/api";
import { phoneReg } from "@/utils/config";
import { apiCallback } from "@/utils";
import NormalDialog from "@/components/layout/NormalDialog.vue";

export default {
    props: ["title"],
    data() {
        return {
            imageYRZ: require("@/assets/img/login/authComIcon.png"),
            imageDSH: require("@/assets/img/icon_daishenhe.png"),
            imageCompanyId: require("@/assets/img/company/company_id.png"),
            imageSuccess: require("@/assets/img/company/success.png"),
            test: "测试信息",
            dialogTableVisible: false,
            stepIndex: 0,
            isNext: false,
            isCode: false,
            disabled: false,
            buttonDisabled: true,
            submitDisabled: true,
            // imgLoad: false,
            imageUrl: "",
            localImageUrl: "", // 上传后展示的图片url
            file: {},
            companyId: 0, //数据库中的企业序号
            // imgUploadPercent: 0,
            getCampany: {},
            stepTwoFlag: 0, // 第二部 0:提交公司信息，验证公司 1：向公司申请
            checked: false, // 申请加入页面，是否选择了企业
            canJoin: false, // 是否可以申请加入：等待认证的企业无法加入
            showSuggest: false, //显示输入建议
            loginTimeoutDialogVisible: false,
            //公司管理员
            Cadmin: {
                adminName: "",
                adminTel: "",
            },
            // 这是登录表单的数据绑定对象
            companyForm: {
                companyName: "",
                companyTel: "",
                companyAddress: "",
                invitationCode: "",
            },
            // 这是表单的验证规则对象
            companyFormRules: {
                companyName: [
                    {
                        required: true,
                        message: "企业名称不能为空",
                        trigger: "blur",
                    },
                ],
                companyTel: [
                    {
                        required: true,
                        message: "企业号码不能为空",
                        trigger: "blur",
                    },
                    {
                        pattern: phoneReg,
                        message: "请输入正确的手机号码",
                    },
                ],
                companyAddress: [
                    {
                        required: true,
                        message: "企业地址不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    components: {
        NormalDialog,
    },
    mounted() {},
    methods: {
        init() {
            this.dialogTableVisible = true;
            this.stepIndex = 0;
            this.disabled = false;
            this.buttonDisabled = true;
            this.submitDisabled = true;
            this.imageUrl = "";
            this.localImageUrl = "";
            this.file = {};
            this.stepTwoFlag = 0;
            this.checked = false;
            this.showSuggest = false;
            this.companyForm = {
                companyName: "",
                companyTel: "",
            };
        },
        closeDialog() {
            this.dialogTableVisible = false;
            this.$emit("dialogClose");
        },
        isCompanyName() {
            this.$refs.companyFormRef.validate((valid) => {
                if (valid) {
                    this.buttonDisabled = false;
                } else {
                    this.buttonDisabled = true;
                }
            });
        },
        isCompanyAll() {
            if (
                this.companyForm.companyTel &&
                this.companyForm.companyAddress &&
                this.imageUrl
            ) {
                this.submitDisabled = false;
            } else {
                this.submitDisabled = true;
            }
        },
        avataListRemove() {
            this.imageUrl = "";
            this.localImageUrl = "";
        },
        beforeUpload(file) {
            const isImage = /image\/\w+/.test(file.type);
            const isLt2M = file.size / 1024 / 1024 < 5;

            if (!isImage) {
                this.$message.error("请上传图片格式文件!");
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 5MB!");
            }
            return isImage && isLt2M;
        },
        async uploadLicense({ file }) {
            var that = this;
            let uploadParam = new FormData(); // 创建form对象
            uploadParam.append("file", file); // 通过append向form对象添加数据
            try {
                const res = await uploadFile(uploadParam);
                const data = apiCallback(res);
                that.localImageUrl = URL.createObjectURL(file);
                that.imageUrl = data.url;
                that.isCompanyAll();
            } catch (err) {
                console.error(err);
            }
        },
        async getSearchComp(searchName) {
            var that = this;
            await getAllCompanyStaff(
                {
                    cname: searchName,
                },
                {
                    showErrorMessage: false,
                },
            )
                .then(async (res) => {
                    that.getCampany = {};
                    that.companyId = "";
                    const data = apiCallback(res);
                    that.getCampany = data;
                })
                .catch((err) => {
                    that.getCampany = {};
                    that.companyId = "";
                    console.error(err);
                });
        },
        async nextStep() {
            const that = this;
            await that.getSearchComp(that.companyForm.companyName);
            let isEmpty = JSON.parse(JSON.stringify(that.getCampany));
            if (Object.keys(isEmpty).length > 0) {
                if (that.getCampany.company.isaccess != 2) {
                    that.stepTwoFlag = 1;
                    if (that.getCampany.company.isaccess == 1) {
                        that.canJoin = true;
                        that.companyId = that.getCampany.company.id;
                        const companyAdmin = that.getCampany.company.uid;
                        let userAdmin = that.getCampany.users.find(
                            (user) => user.id == companyAdmin,
                        );
                        if (userAdmin) {
                            that.Cadmin.adminName = userAdmin.userName;
                            that.Cadmin.adminTel = userAdmin.phone;
                        }
                    } else if (that.getCampany.company.isaccess == 0) {
                        that.canJoin = false;
                        that.Cadmin.adminName = "";
                        that.Cadmin.adminTel = "";
                    }
                }
            } else {
                this.stepTwoFlag = 0;
            }
            // console.log(this.stepTwoFlag)
            this.stepIndex = 1;
            this.disabled = true;
        },
        telDeal(tel) {
            if (tel) {
                return "(" + tel.substr(0, 3) + "****" + tel.substr(7, 4) + ")";
            }
        },
        isJion(id, isaccess) {
            if (isaccess == 1) {
                let idx = this.getCampany.users.findIndex(
                    (item) => item.id == id,
                );
                if (idx != -1) {
                    this.canJoin = false;
                    const uid = this.getCampany.company.uid;
                    if (uid === id) {
                        return "[已加入] 管理员";
                    }
                    return "[已加入] 普通员工";
                } else {
                    return "[未加入]";
                }
            } else if (isaccess == 0) {
                return "";
            }
        },
        preStep() {
            this.stepTwoFlag = 0;
            this.stepIndex = 0;
            this.disabled = false;
        },
        submitMess() {
            const that = this;
            that.stepIndex = 2;
            that.$refs.companyFormRef.validate((valid) => {
                if (!valid) {
                    return;
                }
            });
            if (that.imageUrl) {
                const param = {
                    uid: that.$store.state.user.userId,
                    companyName: that.companyForm.companyName,
                    companyPhone: that.companyForm.companyTel,
                    companyAddress: that.companyForm.companyAddress,
                    invitationCode: that.companyForm.invitationCode,
                    businessLicense: that.imageUrl,
                };
                companyApplyCertification(param)
                    .then(async (res) => {
                        const { message } = apiCallback(res, false);
                        that.$message.success(message);
                        that.$emit("refreshCompanyList");
                        that.stepIndex = 2;
                    })
                    .catch((err) => console.error(err));
            }
        },
        submitApply() {
            // console.log(this.companyId,this.$store.state.user.userId)
            if (!this.companyId || !this.$store.state.user.userId) {
                this.$message.error("error");
                return;
            }
            var that = this;
            var data = {};
            data.uid = this.$store.state.user.userId;
            data.cid = this.companyId;
            staffApplyJoin(data)
                .then(async (res) => {
                    that.$message.success("成功发送申请");
                    that.$emit("refreshNojoinList");
                    that.stepIndex = 2;
                })
                .catch((err) => console.error(err));
        },
        querySearch(queryString, cb) {
            // var colleagueDate = this.getCname
            // var results = queryString ? colleagueDate.filter(this.createFilter(queryString)) : colleagueDate
            // // 调用 callback 返回建议列表的数据
            // cb(results)
            // console.log(cb(results))
        },
        createFilter(queryString) {
            return (colleague) => {
                return (
                    colleague.value
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) === 0
                );
            };
        },
        blurForBug() {
            document.activeElement.blur();
        },
    },
};
</script>
<style lang="stylus" scoped>
.company-id-img {
   position: absolute;
   top:-55px;
   left:-20px
}

/deep/ .el-steps{
    margin:30px 0;
}
.inline-input{
    width:450px;
    margin-top:10px;
}

/deep/ .el-dialog--center .el-dialog__body{
    padding:30px 15px;
}

.license-uploader  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    margin-top: 10px;
    overflow: hidden;
  }
  .license-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .license-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height:100px;
    line-height: 100px;
    text-align: center;
  }
  .license {
    width: 100px;
    height: 100px;
    display: block;
  }
    /deep/ .el-card__body{
        padding:0;
    }
    .jionCompany{
        display:flex;
        justify-content:space-between;
        align-items:center;
        padding:10px;
        border-radius:10px;
        background-color:#ffefef;
        .message{
            display:flex;
        align-items:center;
        }
        .admin{
            display:flex;
            align-items:center;
            font-weight:normal;
            font-size:14px;
        }
        .join-status{
            font-size:14px;
            font-weight:normal;
        }
    }
</style>
